import { ResponseData, ResponseError, axiosInstance, Request } from '@/helpers'
const moduleName = 'tai-khoan'
export const GetAll = () =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}`,req).then(ResponseData).catch(ResponseError)
}
export const GetTransaction = () =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}/transaction`,req).then(ResponseData).catch(ResponseError)
}
export const Active = (id,type) =>{
  const req = Request();
  return axiosInstance.patch(`/${moduleName}/active`,{id,type},req).then(ResponseData).catch(ResponseError)
}
export const Nap = ({id,sotien,freeze,ghichu}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/nap`,{id, sotien, freeze, ghichu},req).then(ResponseData).catch(ResponseError)
}
export const Rut = ({id,sotien,ghichu}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/rut`,{id, sotien, ghichu},req).then(ResponseData).catch(ResponseError)
}
export const Rate = ({id,rate}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/rate`,{id,rate},req).then(ResponseData).catch(ResponseError)
}
export const Approve = (id) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/approve`,{id},req).then(ResponseData).catch(ResponseError)
}
export const Refund = (id) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/refund`,{id},req).then(ResponseData).catch(ResponseError)
}
export default {
  GetAll,
  GetTransaction,
  Active,
  Nap,
  Rut,
  Approve,
  Refund,
  Rate,
}