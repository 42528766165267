<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h6">
          KHO THẺ - TỔNG: {{itemsWithIndex.length}}
        </span>
      </v-card-title>
      <v-card-text>
        <Datatables
          :value.sync="Selected"
          :headers="headers"
          :items="itemsWithIndex"
          :PerPageOption="[50,100,150,200]"
          multiple
          :group="false"
        >
          <template #tools>
            <v-col
              cols="auto"
            >
              <v-btn
                class="mx-2"
                color="blue"
                @click="Box = true"
              >
                Import
              </v-btn>
            <template v-if="Selected.length > 0">
                <v-btn
                  class="mx-2"
                  color="blue"
                  @click="BoxExport = true"
                >
                  Export ({{ExportList.length}})
                </v-btn>
                <v-btn
                  class="mx-2"
                  color="red"
                  @click="doDeleteList"
                >
                  Delete ({{DeleteList.length}})
                </v-btn>
                <v-btn
                  class="mx-2"
                  color="orange"
                  @click="doMoveListOpen"
                >
                  Chuyển ({{MoveList.length}})
                </v-btn>
            </template>
            </v-col>
            <v-col
              cols="auto"
            >
              <v-select
                v-model="Filter.Used"
                :items="StatusList.Used"
                item-value="value"
                item-text="text"
                label="Lọc bởi trạng thái"
              />
              <!-- <v-checkbox
                v-model="ShowUsed"
                color="blue"
                label="Hiện đã sử dụng"
              /> -->
            </v-col>
          </template>
          <template #[`item.Last`]="{ item }">
            <span class="text-h6 green--text" v-if="item.Last === null">Chưa</span>
            <span class="text-h6 orange--text" v-else>{{ThoiGian(item.Last)}}</span>
          </template>
        </Datatables>
        <loading :status="loader" />
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="Box"
      max-width="900px"
      persistent
    >
      <Header
        title="Import Dữ Liệu"
        :close.sync="Box"
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
            >
              <v-list-item class="grow">
                <v-list-item-content>
                  <v-list-item-subtitle>Data</v-list-item-subtitle>
                  <v-list-item-title>
                    <v-textarea
                      v-model="ImportData"
                    />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col
              cols="4"
            >
              <v-list-item class="grow">
                <v-list-item-content>
                  <v-list-item-subtitle>Số lượng Import</v-list-item-subtitle>
                  <v-list-item-title>
                    <span class="text-h6">{{ImportList.length}}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="green"
            :disabled="ImportList.length === 0"
            @click="doImport"
          >
            Import
          </v-btn>
        </v-card-actions>
        <loading :status="Loader" />
      </Header>
    </v-dialog>
    <v-dialog
      v-model="BoxExport"
      max-width="900px"
      persistent
    >
      <Header
        title="Export Dữ Liệu"
        :close.sync="BoxExport"
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
            >
              <v-list-item class="grow">
                <v-list-item-content>
                  <v-list-item-subtitle>Data</v-list-item-subtitle>
                  <v-list-item-title>
                    <v-textarea
                      :value="ExportList.join('\n')"
                    />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col
              cols="4"
            >
              <v-list-item class="grow">
                <v-list-item-content>
                  <v-list-item-subtitle>Số lượng Export</v-list-item-subtitle>
                  <v-list-item-title>
                    <span class="text-h6">{{ExportList.length}}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </Header>
    </v-dialog>
    <Modal
      title="Chuyển kho thẻ"
      :onClose.sync="BoxMove"
      :loader.sync="LoaderMove"
    >
      <template #body>
        <v-select
          v-model="NewUser"
          :items="UserList"
          item-value="_id"
          item-text="email"
          label="Danh sách user"
        />
      </template>
      <template #submit>
        <v-btn
          color="green"
          @click="doMoveList"
          :disabled="!NewUser || UserList.length === 0 || MoveList.length === 0"
        >
          Chuyển
        </v-btn>
      </template>
    </Modal>
  </div>
</template>

<script>
import Datatables from '@/components/base/datatables.vue'
import loading from  '@/components/base/loading.vue'
import Header from  '@/components/base/pagehead.vue'
import Modal from  '@/components/base/modal.vue'
import KTService from '@/modules/kho_the.js';
import AccountService from '@/modules/account.js';
import {ThoiGian} from '@/helpers';
export default {
  components:{
    Datatables,
    loading,
    Header,
    Modal,
  },
  data () {
    return {
      Selected:[],
      Valid:false,
      Box:false,
      BoxExport:false,
      ImportData:undefined,
      loader:false,
      Loader:false,
      search:undefined,
      ShowUsed: false,
      headers:[
        { text: 'Data', align: 'center', sortable: true,value: 'Data',width:"70%",class:"primary secondary--text text-center" },
        { text: 'Sử dụng', align: 'center', sortable: true,value: 'Last',width:"15%",class:"primary secondary--text text-center" },
        { text: 'Thao tác', align: 'center', sortable: false, value: 'controller',width:"15%",class:"primary secondary--text text-center" },
      ],
      items:[],
      controller: [
        {t:'Xóa',c:'red',a:this.doRemove, i:'mdi-delete'},
        {t:'Use',c:'orange',a:this.doUsed, i:'mdi-credit-card-check-outline'},
      ],
      Filter:{
        Used:1,
      },
      StatusList:{
        Used: [{'value':1,'text':'Chưa sử dụng'},{'value':2,'text':'Đã sử dụng'}],
      },
      UserList:[],
      NewUser: undefined,
      LoaderMove:false,
      BoxMove:false,
      autoLoader: undefined,
    }
  },
  computed:{
    ImportList(){
      if(this.ImportData === undefined) return [];
      return this.ImportData.split('\n').filter(v=>v.length > 16);
    },
    ExportList(){
      return this.Selected.filter(v=>v.Data).map(item=>{return item.Data})
    },
    MoveList(){
      return this.Selected.filter(v=>v.Data).map(item=>{return item._id})
    },
    DeleteList(){
      return this.Selected.filter(v=>v.Data).map(item=>{return item._id})
    },
    itemsWithIndex(){
      return this.items.map(
        (item) => {
          const {Used} = this.Filter;
          if(Used === 1 && item.Last) return undefined;
          if(Used === 2 && !item.Last) return undefined;
          return {
            ...item,
            controller:this.controller
          }
        }).filter(v=>!!v)
    },
  },
  methods:{
    ThoiGian,
    doUsed(item){
      const id = item._id;
      this.Loader = true;
      KTService.Used(id).then(resp=> {
        if(resp.success) {
          this.Load();
        }
      }).finally(()=>{
        this.Loader = false;
      });
    },
    doMoveList(){
      this.LoaderMove = true;
      KTService.Move({Cards:this.MoveList,Owner:this.NewUser}).then(resp=> {
        if(resp.success) {
          this.BoxMove = false;
          this.Selected = [];
          this.Load();
        }
      }).finally(()=>{
        this.LoaderMove = false;
      });
    },
    doMoveListOpen(){
      this.BoxMove = true;
      if(this.UserList.length === 0){
        this.LoaderMove = true;
        AccountService.GetAll().then(resp=>{
          if(resp.data) this.UserList = resp.data
        }).finally(()=>{
          this.LoaderMove = false;
        })
      }
    },
    doDeleteList(){
      this.loader = true;
      KTService.RemoveThe({List:this.DeleteList}).then(resp=> {
        if(resp.success) this.Selected = [];
      }).finally(this.Load);
    },
    doImport(){
      this.loader = true;
      KTService.Import({Cards:this.ImportList}).then(resp=>{
        this.Box = false;
      }).finally(this.Load);
    },
    doRemove(item){
      this.loader = true;
      const id = item._id;
      KTService.RemoveThe({id}).finally(this.Load);
    },
    AutoLoader(){
      return KTService.GetAll().then(resp=>{
        const {data} = resp;
        if(data) this.items = data;
      })
    },
    Load(){
			this.Selected = [];
      this.loader = true;
      this.AutoLoader().finally(()=>{
        this.loader = false;
      })
    }
  },
  mounted(){
    this.Load();
    this.autoLoader = setInterval(this.AutoLoader,10000);
  },
  beforeDestroy(){
    if(this.autoLoader) clearInterval(this.autoLoader);
    this.autoLoader = undefined;
  }
}
</script>

<style>

</style>
